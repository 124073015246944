import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const AllStudents = () => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    fetch("https://skillbackend.educationesupport.xyz/std/allstudents")
      .then((response) => response.json())
      .then((data) => setStudents(data))
      .catch((error) => console.error("Error fetching students:", error));
  }, []);

  const handleDelete = async (studentId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/std/delete/${studentId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setStudents(students.filter((student) => student.id !== studentId));
        window.alert("Student successfully deleted");
      } else {
        const errorResponse = await response.json();
        console.error("Failed to delete student:", errorResponse);
        window.alert(`Failed to delete student: ${errorResponse.message}`);
      }
    } catch (error) {
      console.error("Error deleting student:", error);
      window.alert("Error deleting student");
    }
  };

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>ALL STUDENTS</h1>
          <form>
            <input type="text" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>District</th>
                  <th>Province</th>
                  <th>Phone</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th>School</th>
                  <th>Course</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student.id}>
                    <td>{student.name}</td>
                    <td>{student.address}</td>
                    <td>{student.district}</td>
                    <td>{student.province}</td>
                    <td>{student.phone}</td>
                    <td>{student.mobile}</td>
                    <td>{student.email}</td>
                    <td>{student.school}</td>
                    <td>{student.course}</td>
                    <td>{student.category}</td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(student.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllStudents;
