import React, { useState, useEffect } from "react";
import "../assets/css/institutes.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const InternationalSchls = () => {
  const [search, setSearch] = useState("");
  const [schools, setSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(
          "https://skillbackend.educationesupport.xyz/internationalSchools/all"
        ); // Replace with your API endpoint
        setSchools(response.data);
        setFilteredSchools(response.data);
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };
    fetchSchools();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const filtered = schools.filter((school) =>
      school.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredSchools(filtered);
  };

  return (
    <>
      <SecondNav />

      <div className="institutes">
        <div className="leftBar">
          <div className="containerBoxOne">
            <div className="content-warp">
              <h4>FIND YOUR SCHOOL</h4>
              <form id="formInstitutes" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Enter School Name"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
          </div>
        </div>

        <div className="institutesContainer">
          <div className="containerBoxTwo">
            {filteredSchools.map((school) => (
              <a key={school.id} href={`/intschlsview/${school.id}`}>
                <div className="ins-box">
                  <div className="ins-box-img">
                    <img
                      src={`https://skillbackend.educationesupport.xyz/Upload/images/intschls/${school.logo}`}
                      alt="logo"
                    />
                  </div>
                  <div className="ins-box-content">
                    <p>{school.intschlName}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InternationalSchls;
