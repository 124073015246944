import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/courseView.css";
// import ApplyForm from '../components/ApplyNow';
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const ApplicationView = () => {
  const { id } = useParams();
  const [application, setApplications] = useState([]);

  useEffect(() => {
    const fetchApplicationData = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz/applications/${id}`
        );
        console.log(response.data);
        setApplications(response.data);
      } catch (error) {
        console.error("Error fetching application data:", error);
      }
    };

    fetchApplicationData();
  }, [id]);

  if (!application) {
    return null;
  }

  return (
    <div>
      <SecondNav />

      <div className="c_container">
        <div className="c_leftcolumn">
          <div className="programme">
            <h2>{application.appName}</h2>
            <hr id="hr-programme" />
            {/* <div className="c-image">
                {applications.banner && <img src={`https://skillbackend.educationesupport.xyz/Upload/images/applications/${applications.banner}`} alt={applications.appName} />}
            </div> */}
            <h3>Overview</h3>
            <p id="p-overview">{application.description}</p>
          </div>

          <div className="programme">
            <h4>Visit the link below to apply online : </h4>
            <p>
              {application.link ? (
                <a
                  href={application.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {application.link}
                </a>
              ) : (
                "No link available"
              )}
            </p>
          </div>

          <div className="programme">
            <h4>Download application here : </h4>
            <p>
              {application.application ? (
                <a
                  href={`https://skillbackend.educationesupport.xyz/Upload/pdf/${application.application}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="download-btn">Download Application</button>
                </a>
              ) : (
                "No application available"
              )}
            </p>
          </div>

          {/* <div className="programmes">
            <h3>RELATED TUITIONS</h3>
            <hr id="hr-programme" />
            <div className="courseitem">
                <div className="coursetitle">
                <a href="#"><strong>{applications.appName}</strong></a>
                
                </div>
                <p></p>
                <div className="coursedetails">Duration: 2 Years</div>
                <div className="coursedetails">Field: {applications.subject}</div>
                <hr />
                <div className="courseins">By: <a href="#">{applications.ownerName}</a></div>
            </div>
          </div> */}

          {/* <div className="programme">
              <ApplyForm />
          </div> */}
        </div>

        <div className="a_rightcolumn">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-list-alt"></i>
                    <span>Application Name:</span>
                  </div>
                </td>
                <td>
                  <p>{application.appName}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-clock"></i>
                    <span>Due Date & Time:</span>
                  </div>
                </td>
                <td>
                  <p>
                    {new Date(application.expire_time)
                      .toLocaleString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                      .replace(",", " -")}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-user"></i>
                    <span>Owner Name:</span>
                  </div>
                </td>
                <td>
                  <p>{application.ownerName}</p>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-list-alt"></i>
                    <span>Link:</span>
                  </div>
                </td>
                <td>
                  <p><a href={application.link} target="_blank" rel="noopener noreferrer">{application.link}</a></p>
                </td>
              </tr> */}
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-phone"></i>
                    <span>Contact:</span>
                  </div>
                </td>
                <td>
                  <p>{application.contact}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-envelope"></i>
                    <span>Email:</span>
                  </div>
                </td>
                <td>
                  <p>
                    <a href={`mailto:${application.ownerEmail}`}>
                      {application.ownerEmail}
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ApplicationView;
