import React, { useEffect, useRef, useState } from "react";
import "../assets/css/institute-register.css";
import intlTelInput from "intl-tel-input";
import utilsScript from "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.css"; // Assuming CSS import is necessary
import Swal from "sweetalert2";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import axios from "axios";
import uniqid from "uniqid";
import CountryDropdown from "country-dropdown-with-flags-for-react";

const Localjobsc = () => {
  //Institute Register form
  const [formData1, setFormData] = useState({
    id: "",
    jobtit: "",
    joblevel: "",
    jobtype: "",
    salary: "",
    img: "",
    cname: "",
    caddr: "",
    contact: "",
    email: "",
    web: "",
    pdate: "",
    cdate: "",
    _desc: "",
    approve: "false",
    deleted: "false",
  });

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]:
        e.target.name === "img" ? e.target.files[0] : e.target.value,
    }));
  };

  //getDate Function

  function getDate() {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (today = mm + "-" + dd + "-" + yyyy);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata = new FormData();

    formdata.append("id", uniqid()); //unique id
    formdata.append("jobtit", formData1.jobtit);
    formdata.append("joblevel", formData1.joblevel);
    formdata.append("jobtype", formData1.jobtype);
    formdata.append("salary", formData1.salary);
    formdata.append("img", formData1.img);
    formdata.append("cname", formData1.cname);
    formdata.append("caddr", formData1.caddr);
    formdata.append("contact", formData1.contact);
    formdata.append("email", formData1.email);
    formdata.append("web", formData1.web);
    formdata.append("pdate", formData1.pdate);
    formdata.append("cdate", formData1.cdate);
    formdata.append("_desc", formData1._desc);
    formdata.append("approve", formData1.approve);
    formdata.append("deleted", formData1.deleted);

    console.log(formData1);

    axios
      .post(
        "https://skillbackend.educationesupport.xyz/ljobs/register",
        formdata
      )
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Successfully registered",
          text: "After approval we will notify you via email",
          showConfirmButton: false,
          timer: 2500,
          customClass: {
            title: "custom-font", // Apply custom font to the title
            content: "custom-font", // Apply custom font to the text
          },
        }).then(() => {
          window.location.href = "/";
        });
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: {
            title: "custom-font", // Apply custom font to the title
            content: "custom-font", // Apply custom font to the text
          },
        });
      });
  };

  const [options, setOptions] = useState([]);

  const getAll = () => {
    axios
      .get("https://skillbackend.educationesupport.xyz/ljobcate/get")
      .then((response) => {
        console.log(response.data);
        setOptions(response.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <div>
      <div className="instituteregister">
        <NavigationBar />

        <div className="head-content-text">
          <h4>Local Job Register</h4>
        </div>

        <div className="form-content">
          <form onSubmit={handleSubmit}>
            <div className="form-group-1">
              <i class="fa-solid fa-building-columns"></i>
              <input
                type="text"
                placeholder="Company Name"
                name="cname"
                id="name"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-address-book"></i>
              <input
                type="address"
                placeholder="Company Address"
                name="caddr"
                id="address"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1" id="form-con">
              <i class="fa-solid fa-phone"></i>
              <input
                type="tel"
                id="mobile_code"
                defaultValue=""
                name="contact"
                placeholder="Company Phone Number"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-envelope"></i>
              <input
                type="Email"
                placeholder="Company Email"
                name="email"
                id="Email"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-globe"></i>
              <input
                type="url"
                placeholder="Company Website"
                name="web"
                id="Website"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-registered"></i>
              <input
                type="text"
                placeholder="Job Title"
                name="jobtit"
                id="name"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i className="fa-solid fa-registered"></i>
              <select name="joblevel" id="joblevel" onChange={handleChange}>
                <option value="" disabled selected>
                  Select Job Level
                </option>
                <option value="entry">Entry level</option>
                <option value="mid">Mid level</option>
                <option value="senior">Senior level</option>
              </select>
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-registered"></i>

              <select name="jobtype" id="name" onChange={handleChange}>
                <option value="" disabled selected>
                  Job Category
                </option>
                {options.map((option) => {
                  return (
                    <option value={option.name} style={{ color: "black" }}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-registered"></i>
              <input
                type="text"
                placeholder="Salary"
                name="salary"
                id="name"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <label for="file-input" class="drop-container">
                <span
                  class=""
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  Post Date
                </span>
                <input
                  type="date"
                  placeholder="Select Date"
                  name="pdate"
                  id="name"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="form-group-1">
              <label for="file-input" class="drop-container">
                <span
                  class=""
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  Close Date
                </span>
                <input
                  type="date"
                  placeholder="Select Date"
                  name="cdate"
                  id="name"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="form-group-1">
              <label for="file-input" class="drop-container">
                <span class="drop-title">Drop the Advertisement Banner</span>
                <input
                  type="file"
                  accept="image/*"
                  required=""
                  id="file-input"
                  name="img"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="form-group-1 textarea">
              <textarea
                name="_desc"
                id=""
                placeholder="Enter the description of the Institute background........ "
                onChange={handleChange}
              ></textarea>
            </div>

            {/* <div className="form-group-1">
                            <i className="fas fa-university"></i>
                            <select id="universityType" name="inType" onChange={handleChange}>
                                <option value="" disabled selected>Select University / Institute Type</option>
                                <option value="Government">Government</option>
                                <option value="Private">Private</option>
                            </select>
                        </div> */}

            <div className="form-group-1">
              <input type="submit" name="submit" value="Register" />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Localjobsc;
