import React, { useState } from "react";
import "../assets/css/contactus.css";
import Swal from "sweetalert2";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router";

const ContactUs = () => {
  const BaseURL = "https://skillbackend.educationesupport.xyz/contactus/";

  //Contact Us form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    subject: "",
    message: "",
  });

  //hadle change function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //submit function
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);

    axios
      .post(`${BaseURL}send`, formData)
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Message Sent",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.href = "/";
        });
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div className="Contactus">
        <SecondNav />

        <div className="head-content-text">
          <h4>Contact Us</h4>
        </div>

        <div className="form-content-contact">
          <form onSubmit={handleSubmit}>
            <div className="form-block-warp">
              <div className="form-group-1-contact">
                <i class="fa-regular fa-user"></i>
                <input
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  id="name"
                  onChange={handleChange}
                />
              </div>

              <div className="form-group-1-contact">
                <i class="fa-regular fa-envelope"></i>
                <input
                  type="Email"
                  placeholder="Enter Email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-block-warp">
              <div className="form-group-1-contact">
                <i class="fa-solid fa-at"></i>
                <input
                  type="text"
                  placeholder="Enter Subject"
                  name="subject"
                  id="subject"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-1-contact">
                <i class="fa-regular fa-address-book"></i>
                <input
                  type="tel"
                  placeholder="Enter Contact Number"
                  name="contact"
                  id="contact"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-block-warp">
              <div className="form-group-1-contact textarea">
                <textarea
                  name="message"
                  id=""
                  placeholder="Type Your Message Here....... "
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>

            <div className="form-block-warp">
              <div className="form-group-1-contact">
                <input type="submit" name="submit" value="SEND " />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
