import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InstituteDashboard from "./InstituteDashboard";
import "../../assets/css/Institute/CreateCourse.css";
import axios from "axios";
import Swal from "sweetalert2";

const CreateLangQual = () => {
  const institute_id = sessionStorage.getItem("id");

  const [formData, setFormData] = useState({
    langQualName: "",
    university: "",
    teachingMethod: "",
    duration: "",
    courseFee: "",
    payMethod: "",
    description: "",
    instituteAddress: "",
    institutePhone: "",
    instituteEmail: "",
    instituteWebsite: "",
    instituteLocation: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz/ins/one/${institute_id}`
        );
        const institute = response.data[0];
        setFormData((prevData) => ({
          ...prevData,
          university: institute.in_name || "",
          instituteAddress: institute.address || "",
          institutePhone: institute.tel || "",
          instituteEmail: institute.email || "",
          instituteWebsite: institute.website || "",
        }));
      } catch (error) {
        console.error("Error fetching institute details:", error);
      }
    };
    fetchInstituteDetails();
  }, [institute_id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("institute_id", institute_id);

    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/langQualifications/create",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      // Read the response once and store it in a variable
      const responseText = await response.text();

      // Debugging: Log the response status and text
      console.log("Response status:", response.status);
      console.log("Response text:", responseText);

      if (response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: responseText,
          showConfirmButton: true,
        });
      } else if (response.ok) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Language Qualification Added Successfully",
          text: "Your Language Qualification has been added successfully.",
          showConfirmButton: false,
          timer: 2500,
        }).then(() => {
          navigate("/manlangqual");
        });
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        showConfirmButton: true,
      });
    }
  };

  return (
    <main className="institute-create-course">
      <InstituteDashboard />
      <div className="head-content-text">
        <h4>ADD LANGUAGE QUALIFICATION</h4>
      </div>
      <div className="sa-container">
        <form onSubmit={handleSubmit}>
          <div className="new">
            <div className="formgroup">
              <i className="fas fa-book"></i>
              <input
                type="text"
                id="langQualName"
                name="langQualName"
                value={formData.langQualName}
                onChange={handleChange}
                placeholder="Language Exam Name"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                placeholder="University / Institute Name"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-book-reader"></i>
              <select
                id="teachingMethod"
                name="teachingMethod"
                value={formData.teachingMethod}
                onChange={handleChange}
                placeholder="Teaching Method"
              >
                <option value="" disabled>
                  Select Teaching Method
                </option>
                <option value="Online">Online</option>
                <option value="Classroom">Classroom</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-hourglass-start"></i>
              <input
                type="text"
                id="duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                placeholder="Duration"
              />
            </div>

            <div className="formgroup">
              <i className="fa fa-money-bill-alt"></i>
              <input
                type="text"
                id="courseFee"
                name="courseFee"
                value={formData.courseFee}
                onChange={handleChange}
                placeholder="Course Fee"
              />
            </div>

            <div className="formgroup">
              <i className="fa-solid fa-wallet"></i>
              <input
                type="text"
                id="payMethod"
                name="payMethod"
                value={formData.payMethod}
                onChange={handleChange}
                placeholder="Paying Method"
              />
            </div>

            <div className="formgroup textarea">
              <textarea
                name="description"
                placeholder="Enter description for Language Qualification........ "
                onChange={handleChange}
                value={formData.description}
              ></textarea>
            </div>

            {/* Contact Details */}
            <div className="formgroup">
              <h4>Contact Details</h4>
            </div>

            <div className="formgroup">
              <i class="fas fa-address-card"></i>
              <input
                type="text"
                id="instituteAddress"
                name="instituteAddress"
                value={formData.instituteAddress}
                onChange={handleChange}
                placeholder="Institute Address"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="tel"
                id="institutePhone"
                name="institutePhone"
                value={formData.institutePhone}
                onChange={handleChange}
                placeholder="Institute Phone"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="instituteEmail"
                name="instituteEmail"
                value={formData.instituteEmail}
                onChange={handleChange}
                placeholder="Institute Email"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-link"></i>
              <input
                type="text"
                id="instituteWebsite"
                name="instituteWebsite"
                value={formData.instituteWebsite}
                onChange={handleChange}
                placeholder="Institute Website"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="instituteLocation"
                name="instituteLocation"
                value={formData.instituteLocation}
                onChange={handleChange}
                placeholder="Institute Location"
              />
            </div>

            <button type="submit">CREATE</button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default CreateLangQual;
