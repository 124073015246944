import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const AllTuitions = () => {
  const [tuitions, setTuitions] = useState([]);

  useEffect(() => {
    fetchTuitions();
  }, []);

  const fetchTuitions = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/tuition/all"
      );
      const data = await response.json();
      setTuitions(data);
    } catch (error) {
      console.error("Error fetching tuitions:", error);
    }
  };

  const handleDelete = async (tuitionId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/tuition/delete/${tuitionId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setTuitions(tuitions.filter((tuition) => tuition.id !== tuitionId));
      } else {
        console.error("Failed to delete tuition");
      }
    } catch (error) {
      console.error("Error deleting tuition:", error);
    }
  };

  // const handleEdit = (tuitionId) => {
  //     // Navigate to edit page or show edit form in place
  //     // For example, navigate to `/edit-tuition/${tuitionId}` or show form inline
  //     console.log('Edit course with ID:', tuitionId);
  // };

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>ALL TUITIONS</h1>
          <form>
            <input type="text" name="" id="" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Class Name</th>
                  <th>Subject</th>
                  <th>Teacher Name</th>
                  <th>Start Date</th>
                  <th>Time</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tuitions.map((tuition) => (
                  <tr key={tuition.id}>
                    <td>{tuition.className}</td>
                    <td>{tuition.subject}</td>
                    <td>{tuition.teacherName}</td>
                    <td>
                      {new Date(tuition.startDate).toLocaleDateString("en-CA")}
                    </td>
                    <td>
                      {new Date(
                        `1970-01-01T${tuition.time}Z`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </td>
                    <td>{tuition.contact}</td>
                    <td>{tuition.teacherEmail}</td>
                    <td>
                      <a href="#!" id="view-website">
                        WEBSITE
                      </a>
                    </td>
                    <td id="action-buttons">
                      {/* <a href="#!" id='man-details' onClick={() => handleEdit(course.id)}>EDIT</a> */}
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(tuition.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTuitions;
