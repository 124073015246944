import React, { useState, useEffect } from "react";
import InstituteDashboard from "./InstituteDashboard";

const ManageEvents = () => {
  const [events, setEvents] = useState([]);
  const [editingEventId, setEditingEventId] = useState(null);
  const [editedEvent, setEditedEvent] = useState({});

  // Retrieve the institute ID from session storage
  const instituteId = sessionStorage.getItem("id");

  useEffect(() => {
    if (instituteId) {
      fetchEvents();
    }
  }, [instituteId]);

  const fetchEvents = async () => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/events/all?institute_id=${instituteId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleDelete = async (eventId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/events/delete/${eventId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setEvents(events.filter((event) => event.id !== eventId));
      } else {
        console.error("Failed to delete event");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleEdit = (event) => {
    setEditingEventId(event.id);
    setEditedEvent(event);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedEvent({ ...editedEvent, [name]: value });
  };

  const handleSave = async (eventId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/events/update/${eventId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedEvent),
        }
      );
      if (response.ok) {
        setEvents(
          events.map((event) => (event.id === eventId ? editedEvent : event))
        );
        setEditingEventId(null);
      } else {
        console.error("Failed to save event");
      }
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  const handleCancel = () => {
    setEditingEventId(null);
    setEditedEvent({});
  };

  return (
    <div>
      <InstituteDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>MANAGE EVENTS</h1>
          <form>
            <input type="text" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th>University Name</th>
                  <th>Venue</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {events.map((event) => (
                  <tr key={event.id}>
                    <td>
                      {editingEventId === event.id ? (
                        <input
                          type="text"
                          name="eventName"
                          value={editedEvent.eventName}
                          onChange={handleChange}
                        />
                      ) : (
                        event.eventName
                      )}
                    </td>
                    <td>
                      {editingEventId === event.id ? (
                        <input
                          type="text"
                          name="university"
                          value={editedEvent.university}
                          onChange={handleChange}
                        />
                      ) : (
                        event.university
                      )}
                    </td>
                    <td>
                      {editingEventId === event.id ? (
                        <input
                          type="text"
                          name="venue"
                          value={editedEvent.venue}
                          onChange={handleChange}
                        />
                      ) : (
                        event.venue
                      )}
                    </td>
                    <td>
                      {editingEventId === event.id ? (
                        <input
                          type="date"
                          name="date"
                          value={new Date(editedEvent.date)
                            .toISOString()
                            .substring(0, 10)}
                          onChange={handleChange}
                        />
                      ) : (
                        new Date(event.date).toLocaleDateString("en-CA")
                      )}
                    </td>
                    <td>
                      {editingEventId === event.id ? (
                        <input
                          type="text"
                          name="time"
                          value={editedEvent.time}
                          onChange={handleChange}
                        />
                      ) : (
                        event.time
                      )}
                    </td>
                    <td>
                      <a
                        href={event.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="view-web"
                      >
                        Link
                      </a>
                    </td>
                    <td id="action-buttons">
                      {editingEventId === event.id ? (
                        <>
                          <a
                            href="#!"
                            id="man-details"
                            onClick={() => handleSave(event.id)}
                          >
                            SAVE
                          </a>
                          <a href="#!" id="del-details" onClick={handleCancel}>
                            CANCEL
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            href="#!"
                            id="man-details"
                            onClick={() => handleEdit(event)}
                          >
                            EDIT
                          </a>
                          <a
                            href="#!"
                            id="del-details"
                            onClick={() => handleDelete(event.id)}
                          >
                            DELETE
                          </a>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEvents;
