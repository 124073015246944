import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const AllBankStdLoans = () => {
  const [StdLoan, setStdLoan] = useState([]);

  useEffect(() => {
    fetchStdLoan();
  }, []);

  const fetchStdLoan = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/StdLoans/all"
      );
      const data = await response.json();
      setStdLoan(data);
    } catch (error) {
      console.error("Error fetching Student Loans:", error);
    }
  };

  const handleDelete = async (StdLoanId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/StdLoans/delete/${StdLoanId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setStdLoan(StdLoan.filter((StdLoan) => StdLoan.id !== StdLoanId));
      } else {
        console.error("Failed to delete Student Loan");
      }
    } catch (error) {
      console.error("Error deleting Student Loan:", error);
    }
  };

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>ALL STUDENT LOANS</h1>
          <form>
            <input type="text" name="" id="" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Degree Name</th>
                  <th>Institute Name</th>
                  <th>Start Date</th>
                  <th>Duration</th>
                  <th>Contact No.</th>
                  <th>Email</th>
                  <th>Location</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {StdLoan.map((StdLoan) => (
                  <tr key={StdLoan.id}>
                    <td>{StdLoan.degreeName}</td>
                    <td>{StdLoan.university}</td>
                    <td>
                      {new Date(StdLoan.startDate).toLocaleDateString("en-CA")}
                    </td>
                    <td>{StdLoan.duration}</td>
                    <td>{StdLoan.institutePhone}</td>
                    <td>{StdLoan.instituteEmail}</td>
                    <td>{StdLoan.instituteLocation}</td>
                    <td>
                      <a href="#!" id="view-website">
                        WEBSITE
                      </a>
                    </td>
                    <td id="action-buttons">
                      {/* <a href="#!" id='man-details' onClick={() => handleEdit(course.id)}>EDIT</a> */}
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(StdLoan.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBankStdLoans;
