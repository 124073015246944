import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/Institute/createprofexams.css";
import axios from "axios";
import Swal from "sweetalert2";
import uniqid from "uniqid";

const EditIntSchlProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    intschlName: "",
    logo: null,
    branchestableRows: [
      {
        city: "",
        address: "",
        phone: "",
        location: "",
        email: "",
      },
    ],
    commonEmail: "",
    commonPhone: "",
    studyLevel: "",
    description: "",
    facilities: "",
    mainLocation: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz/internationalSchools/${id}`
        );
        const data = response.data;
        setFormData({
          intschlName: data.intschlName,
          logo: data.logo,
          branchestableRows: data.branchestableRows,
          commonEmail: data.commonEmail,
          commonPhone: data.commonPhone,
          studyLevel: data.studyLevel,
          description: data.description,
          facilities: data.facilities,
          mainLocation: data.mainLocation,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e, index, field) => {
    const { name, value } = e.target;

    if (field) {
      const newTableRows = [...formData.branchestableRows];
      newTableRows[index][field] = value;
      setFormData((prevData) => ({
        ...prevData,
        branchestableRows: newTableRows,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, logo: e.target.files[0] });
  };

  const handleAddRow = () => {
    setFormData((prevData) => ({
      ...prevData,
      branchestableRows: [
        ...prevData.branchestableRows,
        {
          city: "",
          address: "",
          phone: "",
          location: "",
          email: "",
        },
      ],
    }));
  };

  const handleRemoveRow = (index) => {
    const newTableRows = formData.branchestableRows.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      branchestableRows: newTableRows,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    for (const key in formData) {
      if (Array.isArray(formData[key])) {
        formDataToSend.append(key, JSON.stringify(formData[key]));
      } else if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.put(
        `https://skillbackend.educationesupport.xyz/internationalSchools/update/${id}`,
        formDataToSend
      );
      if (response.status === 200) {
        Swal.fire(
          "Success",
          "International School Profile Updated Successfully",
          "success"
        );
        navigate("/intschlprofile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      Swal.fire("Error", "Something went wrong. Please try again.", "error");
    }
  };

  return (
    <main className="institute-create-pe">
      <AdminDashboard />
      <div className="head-content-text">
        <h4>Edit International School Profile</h4>
      </div>
      <div className="pe_container">
        <form onSubmit={handleSubmit}>
          <div className="pe-new">
            <div className="pe-formgroup">
              <i className="fas fa-school"></i>
              <input
                type="text"
                name="intschlName"
                value={formData.intschlName}
                onChange={handleChange}
                placeholder="International School Name"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                name="commonEmail"
                value={formData.commonEmail}
                onChange={handleChange}
                placeholder="Common Email Address"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="text"
                name="commonPhone"
                value={formData.commonPhone}
                onChange={handleChange}
                placeholder="Common Phone Number"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-graduation-cap"></i>
              <input
                type="text"
                name="studyLevel"
                value={formData.studyLevel}
                onChange={handleChange}
                placeholder="Study Level (e.g., Primary, Secondary)"
              />
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="url"
                name="mainLocation"
                value={formData.mainLocation}
                onChange={handleChange}
                placeholder="Main Location (Map URL)"
              />
            </div>

            <div className="pe-formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Change Logo</span>
                <input
                  type="file"
                  accept="image/*"
                  id="file-input"
                  name="logo"
                  onChange={handleFileChange}
                />
              </label>
              {formData.logo && (
                <img
                  src={`https://skillbackend.educationesupport.xyz/Upload/Images/intschls/${formData.logo}`}
                  alt="Current Logo"
                  width={100}
                />
              )}
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-info-circle"></i>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Description"
              ></textarea>
            </div>

            <div className="pe-formgroup">
              <i className="fas fa-building"></i>
              <textarea
                name="facilities"
                value={formData.facilities}
                onChange={handleChange}
                placeholder="Facilities Offered"
              ></textarea>
            </div>

            <div className="pe-formgroup-table">
              <h4 className="es-title">Branches Information</h4>
              <table>
                <thead>
                  <tr>
                    <th>City</th>
                    <th>Address</th>
                    <th>Phone</th>
                    <th>Location (Map Link)</th>
                    <th>Branch Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.branchestableRows.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          value={row.city}
                          onChange={(e) => handleChange(e, index, "city")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={row.address}
                          onChange={(e) => handleChange(e, index, "address")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={row.phone}
                          onChange={(e) => handleChange(e, index, "phone")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={row.location}
                          onChange={(e) => handleChange(e, index, "location")}
                        />
                      </td>
                      <td>
                        <input
                          type="email"
                          value={row.email}
                          onChange={(e) => handleChange(e, index, "email")}
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="remove-row-btn"
                          onClick={() => handleRemoveRow(index)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                type="button"
                className="add-row-btn"
                onClick={handleAddRow}
              >
                Add Row
              </button>
            </div>
          </div>
          <button type="submit" className="pe-submit-btn">
            Update
          </button>
        </form>
      </div>
    </main>
  );
};

export default EditIntSchlProfile;
