import React, { useEffect, useState } from "react";
import AdminDashboard from "./AdminDashboard";
import axios from "axios";
import intlTelInput from "intl-tel-input";
import utilsScript from "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.css"; // Assuming CSS import is necessary
import Swal from "sweetalert2";

const PendingInstitute = () => {
  const [institute, setInstitute] = useState([]);

  const getAllDate = () => {
    axios
      .get("https://skillbackend.educationesupport.xyz/ins/approve")
      .then((response) => {
        setInstitute(response.data);
        console.log(institute);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllDate();
  }, []);

  const UpdateDelete = (e, id) => {
    e.preventDefault();

    const ddata = {
      deleted: "true",
    };

    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            "https://skillbackend.educationesupport.xyz/ins/delete/" + id,
            ddata
          )
          .then((response) => {
            console.log(response.data);
            getAllDate();
          })
          .catch((err) => {
            console.log(err);
          });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredInstitutes = institute.filter((institute) =>
    institute.in_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin">
        <div className="search-icon-admin admin-institute-search">
          <h1>ALL INSTITUTE</h1>
          <form>
            <input
              type="text"
              name=""
              onChange={handleSearch}
              id=""
              placeholder="Search Item"
            />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-institute">
            <table>
              <thead>
                <tr>
                  <th>Institute Name</th>
                  <th>Institute Type</th>
                  <th>Request Date</th>
                  <th>Person name</th>
                  <th>Person No</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {filteredInstitutes.length > 0 ? (
                  filteredInstitutes.map((institute) => (
                    <tr key={institute.id}>
                      <td>{institute.in_name}</td>
                      <td>{institute.type}</td>
                      <td>{institute.date}</td>
                      <td>{institute.pname}</td>
                      <td>{institute.pconatct}</td>
                      <td>
                        <button id="view-web">
                          <a href={`/institutesview/${institute.id}`}>
                            SITE VIEW
                          </a>
                        </button>
                      </td>
                      <td id="action-buttons">
                        <button
                          onClick={(e) => {
                            UpdateDelete(e, institute.id);
                          }}
                          id="del-details"
                        >
                          DELETE
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      Search data not found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingInstitute;
