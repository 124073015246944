import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../assets/css/eventview.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const EventView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz/events/${id}`
        );
        console.log(response.data);
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, [id]);

  if (!event) {
    return null;
  }

  const handleContactUs = () => {
    navigate("/contact");
  };

  return (
    <div>
      <SecondNav />
      <div className="event-details">
        <h1>{event.eventName}</h1>
        <div className="event-img">
          {event.banner && (
            <img
              src={`https://skillbackend.educationesupport.xyz/Upload/images/events/${event.banner}`}
              alt={event.eventName}
            />
          )}
        </div>
        <div className="event-date">
          <div className="date-box">
            <div className="date-month">
              {new Date(event.date).toLocaleString("default", {
                month: "short",
              })}
            </div>
            <div className="date-day">{new Date(event.date).getDate()}</div>
          </div>
          <div className="event-info">
            <h2>{event.eventName}</h2>
            <p>{event.venue}</p>
            <p>
              Hosted by <a href={event.inWebsite}>{event.university}</a>
            </p>
          </div>
        </div>
        <p>{event.description}</p>
        <h3></h3>
        <ul>
          {event.highlights &&
            event.highlights.map((highlight, index) => (
              <li key={index}>{highlight}</li>
            ))}
        </ul>
        <p>
          Visit <a href={event.link}>{event.link}</a>
        </p>
        <h3>Contact Details</h3>
        <p>{event.instituteAddress}</p>
        <p>{event.institutePhone}</p>
        <p>{event.instituteEmail}</p>
        <p>
          <a href={event.inWebsite}>{event.inWebsite}</a>
        </p>

        <h3>Send Inquiry</h3>
        <button type="cbutton" onClick={handleContactUs}>
          Contact Us
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default EventView;
