import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/courses.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const LanguageQualification = () => {
  const [isUniversityExpanded, setUniversityExpanded] = useState(false);
  const [isCountryExpanded, setCountryExpanded] = useState(false);
  const [langQualifications, setLangQualifications] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredLangQualifications, setFilteredLangQualifications] = useState(
    []
  );
  const [filters, setFilters] = useState({
    university: "",
    country: "",
  });
  const [
    displayedLangQualificationsCount,
    setDisplayedLangQualificationsCount,
  ] = useState(4);
  const navigate = useNavigate();

  const toggleUniversity = () => setUniversityExpanded(!isUniversityExpanded);
  const toggleCountry = () => setCountryExpanded(!isCountryExpanded);

  const fetchLangQualifications = async () => {
    try {
      const query = new URLSearchParams({
        ...filters,
        search: search, // Include search in the query params
      }).toString();
      const response = await axios.get(
        `https://skillbackend.educationesupport.xyz/langQualifications/all?${query}`
      );
      const data = response.data; // Get data directly from the response
      setLangQualifications(data);
      // Update the filtered list with the fetched data
      setFilteredLangQualifications(
        data.filter(
          (langQualification) =>
            langQualification.className &&
            typeof langQualification.className === "string" &&
            langQualification.className
              .toLowerCase()
              .includes(search.toLowerCase())
        )
      );
    } catch (error) {
      console.error("Error fetching Language Qualifications:", error);
    }
  };

  useEffect(() => {
    fetchLangQualifications();
  }, [filters]); // Trigger fetch when filters change

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredLangQualifications(langQualifications);
    } else {
      setFilteredLangQualifications(
        langQualifications.filter(
          (langQualification) =>
            langQualification.langQualName && // Use the correct field for searching
            typeof langQualification.langQualName === "string" &&
            langQualification.langQualName
              .toLowerCase()
              .includes(search.toLowerCase())
        )
      );
    }
  }, [search, langQualifications]); // Filter qualifications whenever search or langQualifications changes

  const handleLangQualificationsClick = (id) => {
    navigate(`/languagequalificationview/${id}`);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  const handleSeeAllLangQualifications = () => {
    setDisplayedLangQualificationsCount((prevCount) => prevCount + 4);
  };

  const LangQualificationsToShow = filteredLangQualifications.slice(
    0,
    displayedLangQualificationsCount
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchLangQualifications(); // Re-fetch qualifications on submit
  };

  const handleFilterChange2 = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  const [fname, setFjobname] = useState([]);

  useEffect(() => {
    axios
      .get("https://skillbackend.educationesupport.xyz/langQualifications/all")
      .then((res) => {
        setFjobname(res.data);
        console.log(fname);
      });
  }, []);

  const fixedColors = [
    "#ff6347", // Tomato
    "#4682b4", // SteelBlue
    "#32cd32", // LimeGreen
    "#ffa500", // Orange
    "#8a2be2", // BlueViolet
    "#ffd700", // Gold
    "#ff1493", // DeepPink
    "#00bfff", // DeepSkyBlue
    "#ff4500", // OrangeRed
    "#6a5acd", // SlateBlue
    "#7fffd4", // Aquamarine
    "#ff69b4", // HotPink
    "#adff2f", // GreenYellow
    "#ffb6c1", // LightPink
    "#ff8c00", // DarkOrange
    "#6b8e23", // OliveDrab
    "#4682b4", // SteelBlue
    "#f08080", // LightCoral
    "#ff7f50", // Coral
    "#40e0d0", // Turquoise
    "#dc143c", // Crimson
    "#d2691e", // Chocolate
    "#cd5c5c", // IndianRed
    "#f0e68c", // Khaki
    "#7b68ee", // MediumSlateBlue
    "#9acd32", // YellowGreen
    "#8b4513", // SaddleBrown
    "#ffdab9", // PeachPuff
    "#b22222", // FireBrick
    "#c71585", // MediumVioletRed
    "#00fa9a", // MediumSpringGreen
    "#32cd32", // LimeGreen
    "#6a5acd", // SlateBlue
    "#ff4500", // OrangeRed
    "#ff8c00", // DarkOrange
    "#6495ed", // CornflowerBlue
    "#e9967a", // DarkSalmon
    "#9932cc", // DarkOrchid
    "#ffd700", // Gold
    "#f5deb3", // Wheat
    "#ffdead", // NavajoWhite
    "#c0c0c0", // Silver
    "#708090", // SlateGray
    "#2e8b57", // SeaGreen
    "#7f00ff", // Violet
    "#ff1493", // DeepPink
    "#d8bfd8", // Thistle
    "#ffd700", // Gold
    "#dc143c", // Crimson
    "#ffe4e1", // MistyRose
    "#00bfff", // DeepSkyBlue
    "#4169e1", // RoyalBlue
    "#ff69b4", // HotPink
    "#afeeee", // PaleTurquoise
    "#98fb98", // PaleGreen
    "#4682b4", // SteelBlue
  ];

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
          <div className="containerBoxOne-course">
            <div className="content-warp">
              <h4>FIND YOUR LANGUAGE QUALIFICATION</h4>
              <form id="formInstitutes" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Enter Course Name"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
          </div>
          {/* <div className={`Institution-section ${isClassExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleClass}>
                            Class Type <span className="expand-arrow">{isClassExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isClassExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-government" value="Individual" onClick={() => handleFilterChange('classType', 'Individual')} /></li>
                            <li><input type="button" className="button-private" value="Group" onClick={() => handleFilterChange('classType', 'Group')} /></li>
                            <li><input type="button" className="button-phd" value="Hall" onClick={() => handleFilterChange('classType', 'Hall')} /></li> 
                            <li><input type="button" className="button-hnd" value="All" onClick={() => handleFilterChange('classType', 'All')} /></li> 
                            <li><input type="button" className="button-postgraduate-diploma" value="Other" onClick={() => handleFilterChange('classType', 'Other')} /></li> 
                        </ul>
                        <hr />
                    </div> */}
          <div
            className={`countriesSection ${isCountryExpanded ? "active" : ""}`}
          >
            <h3 onClick={toggleCountry}>
              COUNTRY{" "}
              <span className="expand-arrow">
                {isCountryExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul className={`filter-list1 ${isCountryExpanded ? "active" : ""}`}>
              <li>
                <input
                  type="button"
                  className="buttonAustralia"
                  value="Australia"
                  onClick={() => handleFilterChange("country", "Australia")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="buttonCanada"
                  value="Canada"
                  onClick={() => handleFilterChange("country", "Canada")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="buttonChina"
                  value="China"
                  onClick={() => handleFilterChange("country", "China")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="buttonFrance"
                  value="France"
                  onClick={() => handleFilterChange("country", "France")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="buttonGermany"
                  value="Germany"
                  onClick={() => handleFilterChange("country", "Germany")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="buttonIndia"
                  value="India"
                  onClick={() => handleFilterChange("country", "India")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="buttonJapan"
                  value="Japan"
                  onClick={() => handleFilterChange("country", "Japan")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="buttonUK"
                  value="UK"
                  onClick={() => handleFilterChange("country", "UK")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="buttonUSA"
                  value="USA"
                  onClick={() => handleFilterChange("country", "USA")}
                />
              </li>
            </ul>
            <hr />
          </div>

          <div
            className={`Levels-section ${isUniversityExpanded ? "active" : ""}`}
          >
            <h3 onClick={toggleUniversity}>
              UNIVERSITY / INSTITUTE{" "}
              <span className="expand-arrow">
                {isUniversityExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul
              className={`filter-list ${isUniversityExpanded ? "active" : ""}`}
            >
              {fname.map((option, index) => {
                return (
                  <li key={option.university}>
                    <input
                      style={{
                        backgroundColor:
                          fixedColors[index % fixedColors.length],
                      }} // Use fixed colors
                      type="button"
                      className=""
                      value={option.university}
                      onClick={() =>
                        handleFilterChange2("university", option.university)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="right-column">
          {LangQualificationsToShow.map((langQualifications) => (
            <div
              className="Course"
              key={langQualifications.id}
              onClick={() =>
                handleLangQualificationsClick(langQualifications.id)
              }
            >
              {/* <div className="calender">
                            <div className='Date-top'></div>
                            <div className="Date">{new Date(Scholarships.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
                        </div> */}
              <div className="Course-info">
                <a href={`/languagequalificationview/${langQualifications.id}`}>
                  <strong>{langQualifications.langQualName}</strong>
                </a>
                <p>Teaching Method: {langQualifications.teachingMethod}</p>
                <p>Duration: {langQualifications.duration}</p>
                <hr />
                <p1>
                  By :{" "}
                  <a href="#">
                    <strong>{langQualifications.university}</strong>
                  </a>
                </p1>
              </div>
            </div>
          ))}
          {displayedLangQualificationsCount < langQualifications.length && (
            <button
              className="more-scholarships"
              onClick={handleSeeAllLangQualifications}
            >
              SEE MORE LANGUAGE QUALIFICATIONS
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LanguageQualification;
