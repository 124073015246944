import React, { useEffect, useState } from "react";
import AdminDashboard from "../Admin/AdminDashboard";
import axios from "axios";
import Swal from "sweetalert2";

const AllGC = () => {
  const [gofficer, setGofficer] = useState([]);
  const [editingOfficerId, setEditingOfficerId] = useState(null);
  const [editedOfficer, setEditedOfficer] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch all government officers
  const getAllDate = async () => {
    try {
      const response = await axios.get(
        "https://skillbackend.educationesupport.xyz/govofficer/all"
      );
      setGofficer(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllDate();
  }, []);

  // Filter government officers based on search term
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredGofficer = gofficer.filter((officer) =>
    officer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Start editing an officer
  const handleEdit = (officer) => {
    setEditingOfficerId(officer.id);
    setEditedOfficer(officer);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedOfficer({ ...editedOfficer, [name]: value });
  };

  // Save changes for an officer
  const handleSave = async () => {
    axios
      .post(
        `https://skillbackend.educationesupport.xyz/govofficer/update/${editedOfficer.id}`,
        editedOfficer
      )
      .then((res) => {
        console.log(res.data);
        setGofficer(
          gofficer.map((officer) =>
            officer.id === editedOfficer.id ? editedOfficer : officer
          )
        );
        setEditingOfficerId(null);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Successfully Updated",
          timer: 1500,
          customClass: {
            title: "custom-font", // Apply custom font to the title
            content: "custom-font", // Apply custom font to the text
          },
        });
      })
      .catch(() => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: {
            title: "custom-font", // Apply custom font to the title
            content: "custom-font", // Apply custom font to the text
          },
        });
      });
  };

  // Cancel editing mode
  const handleCancel = () => {
    setEditingOfficerId(null);
    setEditedOfficer({});
  };

  // Delete an officer
  const handleDelete = async (officerId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://skillbackend.educationesupport.xyz/govofficer/delete/${officerId}`
          )
          .then((res) => {
            getAllDate();
          })
          .catch(() => {
            console.error("Failed to delete officer");
          });

        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const sendEmail = async (id, name, tel, pass, email, district) => {
    axios
      .post("https://skillbackend.educationesupport.xyz/govofficer/send", {
        id: id,
        name: name,
        tel: tel,
        pass: pass,
        email: email,
        district: district,
      })
      .then((res) => {
        Swal.fire({
          title: "Email Sent!",
          text: "Notified owner via Email.",
          icon: "success",
        });
      });
  };

  return (
    <div>
      <AdminDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>GOVERNMENT OFFICER</h1>
          <form>
            <input
              type="text"
              name="search"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search Item"
            />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact No</th>
                  <th>District</th>
                  <th>Send Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredGofficer.length > 0 ? (
                  filteredGofficer.map((officer) => (
                    <tr key={officer.id}>
                      <td>
                        {editingOfficerId === officer.id ? (
                          <input
                            type="text"
                            name="name"
                            value={editedOfficer.name}
                            onChange={handleChange}
                          />
                        ) : (
                          officer.name
                        )}
                      </td>
                      <td>
                        {editingOfficerId === officer.id ? (
                          <input
                            type="text"
                            name="email"
                            value={editedOfficer.email}
                            onChange={handleChange}
                          />
                        ) : (
                          officer.email
                        )}
                      </td>
                      <td>
                        {editingOfficerId === officer.id ? (
                          <input
                            type="text"
                            name="tel"
                            value={editedOfficer.tel}
                            onChange={handleChange}
                          />
                        ) : (
                          officer.tel
                        )}
                      </td>
                      <td>
                        {editingOfficerId === officer.id ? (
                          <input
                            type="text"
                            name="district"
                            value={editedOfficer.district}
                            onChange={handleChange}
                          />
                        ) : (
                          officer.district
                        )}
                      </td>
                      <td>
                        <button
                          id="blue-admin"
                          onClick={() => {
                            sendEmail(
                              officer.id,
                              officer.name,
                              officer.tel,
                              officer.pass_on,
                              officer.email,
                              officer.district
                            );
                          }}
                        >
                          SEND EMAIL
                        </button>
                      </td>
                      <td id="action-buttons">
                        {editingOfficerId === officer.id ? (
                          <>
                            <button onClick={handleSave} id="app-details">
                              SAVE
                            </button>
                            <button onClick={handleCancel} id="del-details">
                              CANCEL
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => handleEdit(officer)}
                              id="app-details"
                            >
                              UPDATE
                            </button>
                            <button
                              onClick={() => handleDelete(officer.id)}
                              id="del-details"
                            >
                              DELETE
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      Search data not found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllGC;
