import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";
import axios from "axios";
import Swal from "sweetalert2";

const PendingIntSchls = () => {
  const [internationalSchools, setInternationalSchools] = useState([]);

  useEffect(() => {
    fetchInternationalSchools();
  }, []);

  const fetchInternationalSchools = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/internationalSchools/pending"
      );
      const data = await response.json();
      setInternationalSchools(data);
    } catch (error) {
      console.error("Error fetching international schools:", error);
    }
  };

  const handleDelete = async (schoolId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/internationalSchools/delete/${schoolId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setInternationalSchools(
          internationalSchools.filter((school) => school.id !== schoolId)
        );
        window.alert("International School deleted");
      } else {
        console.error("Failed to delete international school");
      }
    } catch (error) {
      console.error("Error deleting international school:", error);
    }
  };

  const handleApprove = async (school) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/internationalSchools/approve/${school.id}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setInternationalSchools(
          internationalSchools.filter((s) => s.id !== school.id)
        );
        window.alert("International School Approved");

        // Now send the approval email
        sendEmail(school);
      } else {
        console.error("Failed to approve international school");
      }
    } catch (error) {
      console.error("Error approving international school:", error);
    }
  };

  const sendEmail = async (school) => {
    const emailData = {
      id: school.id,
      name: school.intschlName,
      email: school.commonEmail,
      subject: "International School Approval Notification",
      message: `
                Dear ${school.intschlName} Team,
    
                We are pleased to inform you that your institute has been approved and added to our system.
    
                If you have any questions or need further clarification, please do not hesitate to contact the admin.
    
                Thank you,
                Education Management System
            `,
    };

    try {
      await axios.post(
        "https://skillbackend.educationesupport.xyz/internationalSchools/send",
        emailData
      );
      Swal.fire({
        title: "Email Sent!",
        text: `Notified ${school.intschlName} via Email.`,
        icon: "success",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      Swal.fire({
        title: "Email Sending Failed",
        text: `Could not send approval email to ${school.intschlName}.`,
        icon: "error",
      });
    }
  };

  return (
    <div>
      <AdminDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>PENDING INTERNATIONAL SCHOOLS</h1>
          <form>
            <input type="text" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>
        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>School Name</th>
                  <th>Contact Person</th>
                  <th>Email</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {internationalSchools.map((school) => (
                  <tr key={school.id}>
                    <td>{school.intschlName}</td>
                    <td>{school.commonPhone}</td>
                    <td>{school.commonEmail}</td>
                    <td>
                      <a
                        href={school.instituteWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit Website
                      </a>
                    </td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="app-details"
                        onClick={() => handleApprove(school)}
                      >
                        APPROVE
                      </a>
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(school.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingIntSchls;
