import React, { useState, useEffect } from "react";
import SADashboard from "./SADashboard";
import "../../assets/css/admin/allcourses.css";

const SAManageCourse = () => {
  const [courses, setCourses] = useState([]);
  const [editingCourseId, setEditingCourseId] = useState(null);
  const [editedCourse, setEditedCourse] = useState({});

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/studyabroad/all"
      );
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const handleDelete = async (courseId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/studyabroad/delete/${courseId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setCourses(courses.filter((course) => course.id !== courseId));
      } else {
        console.error("Failed to delete course");
      }
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  };

  const handleEdit = (course) => {
    setEditingCourseId(course.id);
    setEditedCourse(course);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedCourse({ ...editedCourse, [name]: value });
  };

  const handleSave = async (courseId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/studyabroad/update/${courseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedCourse),
        }
      );
      if (response.ok) {
        setCourses(
          courses.map((course) =>
            course.id === courseId ? editedCourse : course
          )
        );
        setEditingCourseId(null);
      } else {
        console.error("Failed to save course");
      }
    } catch (error) {
      console.error("Error saving course:", error);
    }
  };

  const handleCancel = () => {
    setEditingCourseId(null);
    setEditedCourse({});
  };

  return (
    <div>
      <SADashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>MANAGE COURSES</h1>
          <form>
            <input type="text" name="" id="" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>
        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Country</th>
                  <th>University / Institute</th>
                  <th>Course / Degree Name</th>
                  <th>Level</th>
                  <th>Duration</th>
                  <th>Start Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.id}>
                    <td>
                      {editingCourseId === course.id ? (
                        <input
                          type="text"
                          name="country"
                          value={editedCourse.country}
                          onChange={handleChange}
                        />
                      ) : (
                        course.country
                      )}
                    </td>
                    <td>
                      {editingCourseId === course.id ? (
                        <input
                          type="text"
                          name="university"
                          value={editedCourse.university}
                          onChange={handleChange}
                        />
                      ) : (
                        course.university
                      )}
                    </td>
                    <td>
                      {editingCourseId === course.id ? (
                        <input
                          type="text"
                          name="courseName"
                          value={editedCourse.courseName}
                          onChange={handleChange}
                        />
                      ) : (
                        course.courseName
                      )}
                    </td>
                    <td>
                      {editingCourseId === course.id ? (
                        <input
                          type="text"
                          name="level"
                          value={editedCourse.level}
                          onChange={handleChange}
                        />
                      ) : (
                        course.level
                      )}
                    </td>
                    <td>
                      {editingCourseId === course.id ? (
                        <input
                          type="text"
                          name="duration"
                          value={editedCourse.duration}
                          onChange={handleChange}
                        />
                      ) : (
                        course.duration
                      )}
                    </td>
                    <td>
                      {editingCourseId === course.id ? (
                        <input
                          type="date"
                          name="startDate"
                          value={editedCourse.startDate}
                          onChange={handleChange}
                        />
                      ) : (
                        new Date(course.startDate).toLocaleDateString("en-CA")
                      )}
                    </td>
                    <td id="action-buttons">
                      {editingCourseId === course.id ? (
                        <>
                          <a
                            href="#!"
                            id="man-details"
                            onClick={() => handleSave(course.id)}
                          >
                            SAVE
                          </a>
                          <a href="#!" id="del-details" onClick={handleCancel}>
                            CANCEL
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            href="#!"
                            id="man-details"
                            onClick={() => handleEdit(course)}
                          >
                            EDIT
                          </a>
                          <a
                            href="#!"
                            id="del-details"
                            onClick={() => handleDelete(course.id)}
                          >
                            DELETE
                          </a>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SAManageCourse;
