import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const AllEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/events/all"
      );
      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleDelete = async (eventId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/events/delete/${eventId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setEvents(events.filter((event) => event.id !== eventId));
      } else {
        console.error("Failed to delete event");
      }
    } catch (error) {
      console.error("Error deleting event", error);
    }
  };

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>ALL EVENTS</h1>
          <form>
            <input type="text" name="" id="" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th>University Name</th>
                  <th>Venue</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {events.map((eventItem) => (
                  <tr key={eventItem.id}>
                    <td>{eventItem.eventName}</td>
                    <td>{eventItem.university}</td>
                    <td>{eventItem.venue}</td>
                    <td>
                      {new Date(eventItem.date).toLocaleDateString("en-CA")}
                    </td>
                    <td>{eventItem.time}</td>
                    <td>
                      <a
                        href={eventItem.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="view-website"
                      >
                        Link
                      </a>
                    </td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(eventItem.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllEvents;
