import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/courses.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const PrivateCourses = () => {
  const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState({
    universityType: "Private",
    category: "",
    level: "",
  });
  const [displayedCoursesCount, setDisplayedCoursesCount] = useState(4);

  const navigate = useNavigate();

  const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
  const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);

  const fetchCourses = async () => {
    try {
      const query = new URLSearchParams(filters).toString();
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/course/all?${query}`
      );
      if (response.ok) {
        const data = await response.json();
        setCourses(data);
        setFilteredCourses(data);
      } else {
        console.error("Error fetching courses:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const BaseUrl = "https://skillbackend.educationesupport.xyz/course/all";

  useEffect(() => {
    axios
      .get(`${BaseUrl}`)
      .then((response) => {
        setCourses(response.data);
        setFilteredCourses(response.data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredCourses(courses);
    } else {
      setFilteredCourses(
        courses.filter(
          (course) =>
            course.courseName &&
            typeof course.courseName === "string" &&
            course.courseName.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, courses]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/category/all"
      );
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        console.error("Error fetching categories:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchCategories();
  }, [filters]);

  const handleCourseClick = (id) => {
    navigate(`/courseview/${id}`);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  const handleSeeAllCourses = () => {
    setDisplayedCoursesCount((prevCount) => prevCount + 4);
  };

  // Show filtered courses instead of all courses
  const coursesToShow = filteredCourses.slice(0, displayedCoursesCount);
  // Define an array of fixed colors
  const fixedColors = [
    "#ff6347", // Tomato
    "#4682b4", // SteelBlue
    "#32cd32", // LimeGreen
    "#ffa500", // Orange
    "#8a2be2", // BlueViolet
    "#ffd700", // Gold
    "#ff1493", // DeepPink
    "#00bfff", // DeepSkyBlue
    "#ff4500", // OrangeRed
    "#6a5acd", // SlateBlue
    "#7fffd4", // Aquamarine
    "#ff69b4", // HotPink
    "#adff2f", // GreenYellow
    "#ffb6c1", // LightPink
    "#ff8c00", // DarkOrange
    "#6b8e23", // OliveDrab
    "#4682b4", // SteelBlue
    "#f08080", // LightCoral
    "#ff7f50", // Coral
    "#40e0d0", // Turquoise
    "#dc143c", // Crimson
    "#d2691e", // Chocolate
    "#cd5c5c", // IndianRed
    "#f0e68c", // Khaki
    "#7b68ee", // MediumSlateBlue
    "#9acd32", // YellowGreen
    "#8b4513", // SaddleBrown
    "#ffdab9", // PeachPuff
    "#b22222", // FireBrick
    "#c71585", // MediumVioletRed
    "#00fa9a", // MediumSpringGreen
    "#32cd32", // LimeGreen
    "#6a5acd", // SlateBlue
    "#ff4500", // OrangeRed
    "#ff8c00", // DarkOrange
    "#6495ed", // CornflowerBlue
    "#e9967a", // DarkSalmon
    "#9932cc", // DarkOrchid
    "#ffd700", // Gold
    "#f5deb3", // Wheat
    "#ffdead", // NavajoWhite
    "#c0c0c0", // Silver
    "#708090", // SlateGray
    "#2e8b57", // SeaGreen
    "#7f00ff", // Violet
    "#ff1493", // DeepPink
    "#d8bfd8", // Thistle
    "#ffd700", // Gold
    "#dc143c", // Crimson
    "#ffe4e1", // MistyRose
    "#00bfff", // DeepSkyBlue
    "#4169e1", // RoyalBlue
    "#ff69b4", // HotPink
    "#afeeee", // PaleTurquoise
    "#98fb98", // PaleGreen
    "#4682b4", // SteelBlue
  ];

  // Function to handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
          <div className="containerBoxOne-course">
            <div className="content-warp">
              <h4>FIND YOUR COURSE</h4>
              <form id="formInstitutes" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Enter Course Name"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
          </div>
          <div
            className={`Categories-section ${
              isCategoriesExpanded ? "active" : ""
            }`}
          >
            <h3 onClick={toggleCategories}>
              CATEGORIES{" "}
              <span className="expand-arrow">
                {isCategoriesExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul
              className={`filterlist ${isCategoriesExpanded ? "active" : ""}`}
            >
              {categories.map((category, index) => (
                <li key={category.id}>
                  <input
                    type="button"
                    style={{
                      backgroundColor: fixedColors[index % fixedColors.length],
                    }} // Use fixed colors
                    value={category.category}
                    onClick={() =>
                      handleFilterChange("category", category.category)
                    }
                  />
                </li>
              ))}
            </ul>
            <hr />
          </div>
          <div className={`Levels-section ${isLevelsExpanded ? "active" : ""}`}>
            <h3 onClick={toggleLevels}>
              LEVELS{" "}
              <span className="expand-arrow">
                {isLevelsExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? "active" : ""}`}>
              <li>
                <input
                  type="button"
                  className="button-foundation"
                  value="Foundation"
                  onClick={() => handleFilterChange("level", "Foundation")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-training"
                  value="Training"
                  onClick={() => handleFilterChange("level", "Training")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-certificate"
                  value="Certificate"
                  onClick={() => handleFilterChange("level", "Certificate")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-diploma"
                  value="Diploma"
                  onClick={() => handleFilterChange("level", "Diploma")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-advanced-diploma"
                  value="Advanced Diploma"
                  onClick={() =>
                    handleFilterChange("level", "Advanced Diploma")
                  }
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-hnd"
                  value="HND"
                  onClick={() => handleFilterChange("level", "HND")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-degree"
                  value="Degree"
                  onClick={() => handleFilterChange("level", "Degree")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-bachelor"
                  value="Bachelor"
                  onClick={() => handleFilterChange("level", "Bachelor")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-postgraduate-diploma"
                  value="Postgraduate Diploma"
                  onClick={() =>
                    handleFilterChange("level", "Postgraduate Diploma")
                  }
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-masters"
                  value="Masters"
                  onClick={() => handleFilterChange("level", "Masters")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-phd"
                  value="PhD"
                  onClick={() => handleFilterChange("level", "PhD")}
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="right-column">
          {coursesToShow.map((course) => (
            <div
              className="Course"
              key={course.id}
              onClick={() => handleCourseClick(course.id)}
            >
              <div className="calender">
                <div className="Date-top"></div>
                <div className="Date">
                  {new Date(course.startDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })}
                </div>
              </div>
              <div className="Course-info">
                <a href={`/courseview/${course.id}`}>
                  <strong>{course.courseName}</strong>
                </a>
                <p>
                  Start Date:{" "}
                  {new Date(course.startDate).toLocaleDateString("en-CA")}
                </p>
                <p>Duration: {course.duration}</p>
                <p>Level: {course.level}</p>
                <p>Field: {course.category}</p>
                <hr />
                <p1>
                  Institute:{" "}
                  <a href="#">
                    <strong>{course.university}</strong>
                  </a>
                </p1>
              </div>
            </div>
          ))}
          {displayedCoursesCount < courses.length && (
            <button className="more-courses" onClick={handleSeeAllCourses}>
              SEE MORE COURSES
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivateCourses;
