import React, { useState, useEffect } from "react";
import InstituteDashboard from "./InstituteDashboard";

const DeleteProfExamsIns = () => {
  const [professionalExams, setProfessionalExams] = useState([]);

  useEffect(() => {
    fetchDeletedProfExams();
  }, []);

  const fetchDeletedProfExams = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/professionalExams/deleted"
      );
      const data = await response.json();
      setProfessionalExams(data);
    } catch (error) {
      console.error("Error fetching deleted Professional Exams:", error);
    }
  };

  const handleRestore = async (examId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/professionalExams/restore/${examId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setProfessionalExams(
          professionalExams.filter((exam) => exam.id !== examId)
        );
        window.alert("Professional Exam Restored");
      } else {
        console.error("Failed to restore Professional Exam");
      }
    } catch (error) {
      console.error("Error restoring Professional Exam:", error);
    }
  };

  const handlePermanentDelete = async (examId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/professionalExams/delete/permanent/${examId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setProfessionalExams(
          professionalExams.filter((exam) => exam.id !== examId)
        );
        window.alert("Professional Exam deleted permanently");
      } else {
        console.error("Failed to delete Professional Exam permanently");
      }
    } catch (error) {
      console.error("Error deleting Professional Exam permanently:", error);
    }
  };

  return (
    <div>
      <InstituteDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>DELETE PROFESSIONAL EXAMS</h1>
          <form>
            <input type="text" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Exam Name</th>
                  <th>Short Name</th>
                  <th>Institution</th>
                  <th>Start Date</th>
                  <th>Duration</th>
                  <th>Fee</th>
                  <th>Contact No.</th>
                  <th>Email</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {professionalExams.map((exam) => (
                  <tr key={exam.id}>
                    <td>{exam.examName}</td>
                    <td>{exam.shortName}</td>
                    <td>{exam.university}</td>
                    <td>
                      {new Date(exam.startDate).toLocaleDateString("en-CA")}
                    </td>
                    <td>{exam.duration}</td>
                    <td>{exam.fee}</td>
                    <td>{exam.institutePhone}</td>
                    <td>{exam.instituteEmail}</td>
                    <td>
                      <a
                        href={exam.instituteWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Website
                      </a>
                    </td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="restore-details"
                        onClick={() => handleRestore(exam.id)}
                      >
                        RESTORE
                      </a>
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handlePermanentDelete(exam.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProfExamsIns;
