import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const DeleteTuitions = () => {
  const [tuitions, setTuitions] = useState([]);

  useEffect(() => {
    fetchDeletedTuitions();
  }, []);

  const fetchDeletedTuitions = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz/tuition/deleted"
      );
      const data = await response.json();
      setTuitions(data);
    } catch (error) {
      console.error("Error fetching deleted tuitions:", error);
    }
  };

  const handleRestore = async (tuitionId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/tuition/restore/${tuitionId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setTuitions(tuitions.filter((tuition) => tuition.id !== tuitionId));
        window.alert("Course Restored");
      } else {
        console.error("Failed to restore tuition");
      }
    } catch (error) {
      console.error("Error restoring tuition:", error);
    }
  };

  const handlePermanentDelete = async (tuitionId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz/tuition/delete/permanent/${tuitionId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setTuitions(tuitions.filter((tuition) => tuition.id !== tuitionId));
        window.alert("Course delete permanently");
      } else {
        console.error("Failed to delete tuition permanently");
      }
    } catch (error) {
      console.error("Error deleting tuition permanently:", error);
    }
  };

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>DELETED TUITIONS</h1>
          <form>
            <input type="text" name="" id="" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Class Name</th>
                  <th>Subject</th>
                  <th>Teacher Name</th>
                  <th>Start Date</th>
                  <th>Time</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tuitions.map((tuition) => (
                  <tr key={tuition.id}>
                    <td>{tuition.className}</td>
                    <td>{tuition.subject}</td>
                    <td>{tuition.teacherName}</td>
                    <td>
                      {new Date(tuition.startDate).toLocaleDateString("en-CA")}
                    </td>
                    <td>
                      {new Date(
                        `1970-01-01T${tuition.time}Z`
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </td>
                    <td>{tuition.contact}</td>
                    <td>{tuition.teacherEmail}</td>
                    <td>
                      <a href="#!" id="view-website">
                        WEBSITE
                      </a>
                    </td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="restore-details"
                        onClick={() => handleRestore(tuition.id)}
                      >
                        RESTORE
                      </a>
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handlePermanentDelete(tuition.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteTuitions;
